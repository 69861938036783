import { Checkbox, HorizontalGrid, ProgressBar, Select, Spinner } from "@shopify/polaris";
import { useSellingPlanSyncStore } from "./sellingPlanSyncStore";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";

import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";
import {
  streamShopifyOrdersToDb,
  streamShopifyProductsToDb,
  streamShopifySubscriptionContractsToDb,
} from "@vendor-app/app/_state/actionCreators/initialize";
import { useActiveOrganizationSelector } from "@vendor-app/app/_state/reducers/organizations";
import { useSmartrrVendorDispatch, useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";
import { syncSellingPlanGroups } from "@vendor-app/utils/sellingPlanGroupProductManagement";

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;

  & .Polaris-Spinner {
    display: block;
    width: 33.2833px;

    & svg {
      height: 16px;
      width: 16px;
    }
  }
`;
const CheckBoxWrapper = styled.div`
  margin-top: 11px;
`;

export function StreamShopifyEntitiesSelect() {
  const userIsLoading = useSmartrrVendorSelector(state => state.auth.isLoading);
  const activeOrg = useActiveOrganizationSelector();
  const dispatch = useSmartrrVendorDispatch();
  const { addToast } = useToast();
  const [forceChecked, setForceChecked] = useState(false);
  const [reverseChecked, setReverseChecked] = useState(false);
  const [selected, setSelected] = useState("products");
  const [isLoading, setIsLoading] = useState(false);
  const [isStreamingPrograms, setStreamingPrograms] = useState(false);
  const sellingPlanGroupSync = useSellingPlanSyncStore();

  const handleSelectChange = useCallback((value: string) => setSelected(value), []);

  const changeForceCheck = useCallback((newForceChecked: boolean) => {
    setForceChecked(newForceChecked);
  }, []);

  const changeReverseCheck = useCallback((newReverseChecked: boolean) => {
    setReverseChecked(newReverseChecked);
  }, []);

  const legacySyncSellingPlanGroups = useCallback(async () => {
    setStreamingPrograms(true);
    const syncedPlans = await syncSellingPlanGroups();
    if (syncedPlans.type === "error") {
      setStreamingPrograms(false);
      return addToast(syncedPlans.message);
    }
    setStreamingPrograms(false);
    setIsLoading(false);
    return addToast("Subscription programs sync complete");
  }, []);

  useEffect(() => {
    if (isStreamingPrograms && !sellingPlanGroupSync.isSyncing) {
      const timeout = setTimeout(() => {
        setStreamingPrograms(false);
        setIsLoading(false);
        addToast("Subscription programs sync complete");
      }, 500);

      return () => clearTimeout(timeout);
    }
  }, [isStreamingPrograms, sellingPlanGroupSync.isSyncing]);

  const sellingPlanGroupSyncProgress = useMemo(() => {
    return Math.floor((sellingPlanGroupSync.completed / sellingPlanGroupSync.total) * 100);
  }, [sellingPlanGroupSync]);

  const onClick = useCallback(async () => {
    setIsLoading(true);
    if (!userIsLoading && activeOrg) {
      switch (selected) {
        case "products": {
          await dispatch(streamShopifyProductsToDb(activeOrg.id, forceChecked));
          setIsLoading(false);
          return addToast("Products sync complete");
        }
        case "orders": {
          await dispatch(streamShopifyOrdersToDb(activeOrg.id, forceChecked));
          setIsLoading(false);
          return addToast("Orders sync complete");
        }
        case "contracts": {
          await dispatch(streamShopifySubscriptionContractsToDb(activeOrg.id, forceChecked, reverseChecked));
          setIsLoading(false);
          return addToast("Subscription contracts sync complete");
        }
        case "programs": {
          setStreamingPrograms(true);
          void sellingPlanGroupSync.actions.syncSellingPlans(activeOrg.id);
          return;
        }

        default: {
          setIsLoading(false);
          return addToast("Unable to Sync");
        }
      }
    }
  }, [userIsLoading, activeOrg, selected, forceChecked, reverseChecked]);

  return (
    <React.Fragment>
      <Select
        label="Select data to sync:"
        options={[
          { label: "Products", value: "products" },
          { label: "Orders", value: "orders" },
          { label: "Subscription Contracts", value: "contracts" },
          { label: "Subscription Programs", value: "programs" },
        ]}
        onChange={handleSelectChange}
        value={selected}
      />
      <CheckBoxWrapper>
        {selected !== "programs" && (
          <Checkbox label={"Force"} checked={forceChecked} onChange={changeForceCheck} />
        )}
        {selected === "contracts" && (
          <span style={{ paddingLeft: "1rem" }}>
            <Checkbox label={"Reverse"} checked={reverseChecked} onChange={changeReverseCheck} />
          </span>
        )}
      </CheckBoxWrapper>

      <HorizontalGrid alignItems={"center"} columns={{ xs: "1fr auto" }} gap={{ xs: "100" }}>
        {isStreamingPrograms ? <ProgressBar progress={sellingPlanGroupSyncProgress} /> : null}
        <ButtonWrapper>
          <button className="Polaris-Button" onClick={onClick} type="button" disabled={isLoading}>
            <span className="Polaris-Button__Content">
              <span className="Polaris-Button__Text">{isLoading ? <Spinner /> : "Sync"}</span>
            </span>
          </button>
        </ButtonWrapper>
      </HorizontalGrid>
    </React.Fragment>
  );
}
